<template>
  <section id="clients" class="Clients">
    <div class="Wrapper">
      <h2 class="Title Title--h2 Clients-Title"></h2>
    </div>
    <swiper
      class="Clients-Slider swiper"
      :options="sliderLogosOptions"
      ref="slider"
    >
      <swiper-slide
        class="Clients-SliderSlide"
        v-for="(client, i) in clients"
        :key="i"
      >
        <img :src="require(`@/assets/images/${client}`)" alt="photo">
      </swiper-slide>    
    </swiper>
  </section>
</template>
<script>
import {textAnimation, isInViewport} from "../assets/utils/utils";
import { Autoplay } from 'swiper'

export default {
  name: "Clients",
  data() {
    return {
      clients: [
        'betway.svg',
        'coral.svg',
        'dunder.svg',
        'foxygames.svg',
        'friday.svg',
        'ggpoker.svg',
        'betsson.svg',
        'casumo.svg',
        'ladbrokes.svg',
        'leovegas.svg',
        'lotter.svg',
        'mrgreen.svg',
        'rizk.svg',
        'mrq.svg',
        'parymatch.svg',
        'winline.svg',
      ],
      isTextTyped: false,
      sliderLogosOptions: {
        modules: [Autoplay],
        slidesPerView: 'auto',
        spaceBetween: 12,
        loop: true,
        loopAdditionalSlides: 10,
        speed: 5000,
        autoplay: {
          delay: 0
        },
        breakpoints: {
          960: {
            spaceBetween: 12
          },
          1248: {
            spaceBetween: 24
          }
        }
      }
    }
  },
  methods: {
    scrollHandler() {
      if (!this.isTextTyped && isInViewport('.Clients')) {
        this.isTextTyped = true
        const texts = [
          {
            selector: '.Clients-Title',
            text: this.$t('clients.title')
          }
        ]

        textAnimation(texts)          
      }        
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style lang="scss">
.Clients {
  padding: 20px 0 15px;
  scroll-margin-top: 70px;

  @media (min-width: $screen-l) {
    padding: 60px 0 50px;
  }

  &-Title {
    margin-bottom: 20px;
    height: 111px;
    @media (min-width: $screen-l) {
      margin-bottom: 80px;
      height: 150px;
    }
  }

  &-Slider {
    .swiper-wrapper {
      transition-timing-function: linear;
    }    
  }

  &-SliderSlide {
    width: 160px !important;
    
    @media (min-width: $screen-l) {
      width: 327px !important;
    }
  }
}
</style>